<template>
  <div id="history">
    <Sidebar 
      :active="active" 
      v-if="showSidebar" 
      @close="toggleSidebar" 
      @logout="logout" 
      @view="viewPrivateKey"
      @import="importKey"
      @generate="generateKey('show')"
    />
    <div id="content">
      <div style="font-size:2.5rem;">
        <b-icon icon="arrow-bar-left" class="border rounded p-2" @click="toggleSidebar" style="cursor:pointer;"></b-icon>
      </div>
      <!-- <div class="search input-group mt-4">
        <input type="text" class="form-control search-input" placeholder="Search transaction">
        <div class="input-group-append d-flex flex-column justify-content-center">
          <button class="btn btn-search">
              <b-icon icon="search" style="cursor:pointer;"></b-icon>
          </button>
        </div>
      </div> -->
      <div class="history-content">
        <h4>{{ $t('history.recentTransactions') }}</h4>
        <table>
          <tr>
            <th>{{ $t('history.timestamp') }}</th>
            <th>{{ $t('history.amount') }}</th>
            <th>{{ $t('history.inboundAddress') }}</th>
            <th>{{ $t('history.outboundAddress') }}</th>
            <th>{{ $t('history.txnId') }}</th>
            <th>{{ $t('history.status') }}</th> 
          </tr>
          <tr v-for="item in historyList" :key="item.block_hash">
            <td >{{ item.processorResultTimestamp }}</td>
            <td class="th-orange">{{item.currency }} {{ formatAmount(item.headerAmount) }}</td>
            <td class="th-orange"> {{ item.inboundCryptoAddr }}  </td>
            <td class="th-orange"> {{ item.outboundCryptoAddr }}  </td>
            <td class="th-orange"> {{ item.txnId }}  </td>
            <td> {{ item.txnStatus }} </td> 
          </tr>
        </table>
      </div> 
      <div class="history-content">
        <h4>{{ $t('history.recentTransfers') }}</h4>
        <table>
          <tr>
            <th>{{ $t('history.timestamp') }}</th>
            <th>{{ $t('history.hash') }}</th>
            <th>{{ $t('history.input') }}</th>
            <th>{{ $t('history.output') }}</th>
            <th>{{ $t('history.amount') }}</th> 
          </tr>
          <tr v-for="item in history" :key="item.block_hash" v-if="item.method == 'transfer' && !isBlockchainHistoryLoading ">
            <td >{{ item.timestamp }}</td>
            <td class="th-orange"> <div class="hash"><a :href="'https://homs-explorer.blxq.org/tx/' + item.tx_hash" target="_blank">{{ item.tx_hash }}</a> </div> </td>
            <td class="th-orange"> {{ item.from.hash }}  </td>
            <td class="th-orange">{{ item.to.hash }}</td>
            <td> HOMS {{ formatAmount(item.total.value) }} </td> 
          </tr>

          <tr v-if="isBlockchainHistoryLoading">
            <td colspan="5" > <div class="loader-container">  <img class="loader" src="@/assets/loading.gif"/>  </div> </td>
          </tr>
        </table>
      </div> 
    </div>
    <ConfirmLogout />
    <PrivateKey />
    <GeneratePrivateKey :state="generateState"/>
    <ImportPrivateKey />
  </div>
</template>

<script>
import numeral from 'numeral' 

  import { ethers, BigNumber } from 'ethers'
  import HomsToken from '../artifacts/HomsToken.json'
import { testAlphanumeric } from '@chenfengyuan/vue-qrcode'

  export default {
    components: {
      Sidebar:  () => import('../components/Sidebar.vue'),

      // Modals
      ConfirmLogout:  () => import('../components/modal/ConfirmLogout.vue'),
      PrivateKey:  () => import('../components/modal/privateKey/PINPrivateKey.vue'),
      ImportPrivateKey:  () => import('../components/modal/privateKey/ImportPrivateKey.vue'),
      GeneratePrivateKey:  () => import('../components/modal/privateKey/GeneratePrivateKey.vue'),
    },

    data() {
      return {
        showSidebar: false,
        generateState: "",
        active: 'history',
        history: [],
        historyList: [],
        targetNetwork: '',
        targetNetworkId: '',
        isBlockchainHistoryLoading: true,
      }
    },

    computed: {
      init() {
          return this.$store.state.init;
      },
    },

    methods: {
      toggleSidebar() {
        this.showSidebar = !this.showSidebar
        this.active = 'history'
      },

      formatAmount: function(num){
            return numeral(num).format('0,0.00000000');
        },

      logout: function(){
        this.$bvModal.show('logout-modal'); 
      },

      viewPrivateKey() {
        this.active="privatekey";
        console.log(this.active)
        console.log('..')
        this.init.viewPrivKey.state = 'pin';
        this.$bvModal.show('pin-key-modal'); 
      },

      generateKey(isShown){
        this.generateState = isShown
        this.$bvModal.show('generate-key-modal');
        setTimeout(()=>{
          this.generateState = ""
        },5000);
      },

      importKey: function(){
        this.$bvModal.show('import-key-modal'); 
      },

      getProfile: function(){
        const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/profile/',
        };

        this.axios(options)
          .then((response) => {
              this.init.profile = response.data;

              if(this.isBlank(response.data.metamask)) {
                this.getWalletAddress();
              } else {
                this.init.wallet.address = response.data.metamask;
                 this.getHistory();
              }

             
          })
      },

      isBlank: (string) => {
          return string == '' || string == undefined || string== null;
        },
      getHistory: function() {
 

       let smartContract = this.init.isTest ? "0x3219B80A6Cf1050aCd84D7B73fb03237556BeE21" : "0x8D377F0F1E2B7bcDcC1d6Db371C61DC7cF3de808";

       
        const params = {
          type: 'ERC-20,ERC-721,ERC-1155',
          filter: 'to | from',
          token: smartContract
        }

        const data = Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

            console.log(data)

        const options = {
            method: 'GET',
            url: '/blxq-api/v2/addresses/' + this.init.wallet.address + '/token-transfers' + "?"+data,
        };

        this.axios(options)
          .then((response) => {
              this.history = response.data.items;
              this.history.forEach((value) => {
                console.log(value)
                value.timestamp = new Date(value.timestamp).toLocaleString()
                value.total.value = ethers.utils.formatUnits(value.total.value, 18)
              })
              console.log(this.history)
              this.isBlockchainHistoryLoading = false;
              
          })
      },

      getBuyHistory() {
        const options = {
            method: 'GET',
            url: '/cx/txn/cryptoreceive',
        };

        this.axios(options)
        .then((response) => {
          console.log(response.data)
          this.historyList = response.data;
          this.historyList.forEach((value) => {
              console.log(value)
              value.processorResultTimestamp = new Date(value.processorResultTimestamp).toLocaleString()
            })
            console.log(this.historyList)

            this.getBonusHistory();
        })
      },

      getBonusHistory() {
        const options = {
            method: 'GET',
            url: '/cx/txn/cryptotransfer',
        };

        this.axios(options)
        .then((response) => {
          console.log(response.data)
 

          this.historyList = this.history.concat(response.data) ;
          this.historyList.forEach((value) => {
              console.log(value)
              value.processorResultTimestamp = new Date(value.processorResultTimestamp).toLocaleString()
            })
            console.log(this.historyList)
        })
      },

      addressShort(address) { 
        return `${address.slice(
          0,
          2
        )}...${address.slice(-4)}`
      },




      async checkBalance() { 
        let balance = await this.init.contract.balanceOf(this.init.wallet.address)
        balance = ethers.utils.formatUnits(balance, 18)
        console.log('balance :>> ', balance)
        this.init.wallet.balance = balance
         
      },

      async checkNetwork() {
        console.log(`Target network is ${this.targetNetworkId}`)

        if (window.ethereum) {
          const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
          })
          console.log('Current network  :>> ', currentChainId)

          if (currentChainId == this.targetNetworkId) {
            this.connectWallet();
            this.init.wallet.networkOk = true
          } else {
            this.init.wallet.networkOk = false
            this.$bvModal.show('metamask-alert');
          }
        }
      },

      async addNetwork() {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: this.targetNetworkId,
              chainName: this.targetNetwork,
              rpcUrls: [process.env.VUE_APP_ORIGIN_NETWORK_RPC],
              nativeCurrency: {
                name: 'BLXQ',
                symbol: 'BLXQ', // 2-6 characters long
                decimals: 18,
              },
            },
          ],
        })
        // refresh
        window.location.reload()
      },

      async connectWallet(){
        try {
         
          const data = await window.ethereum.request({
            method: 'eth_requestAccounts',
          })
          console.log('data :>> ', data)

          this.init.wallet.address = data[0];
          this.init.wallet.network = this.targetNetwork;
          this.init.wallet.isConnected = true;

          this.checkBalance();
          
          this.getBuyHistory();
        
          console.log('DApp connected to your wallet 💰')
        } catch (error) {
          console.error('Error connecting DApp to your wallet')
          console.error(error)
        }
      },
      getWalletAddress() {
          const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '  /cx/cryptoaddress/HOMS',
          };
          this.axios(options)
            .then((response) => { 
              this.init.wallet.address = response.data.addressValue
                 this.getHistory();
           
            })
            .catch((error) => {
              
            })
          },
      checkSession: function() {
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
            
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/login";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/setup-profile";
              },1000)
                
              }  

            })
      },
    },

    mounted() {
      this.checkSession();
      this.getBuyHistory();
    },

    beforeMount() {
      this.targetNetworkId = this.init.isTest ? "0x453" : "0x454";
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    const signer = provider.getSigner()




    this.init.contract = new ethers.Contract(
      // process.env.VUE_APP_ORIGIN_TOKEN_ADDRESS,
      '0x8D377F0F1E2B7bcDcC1d6Db371C61DC7cF3de808',
      HomsToken.abi,
      signer
    );

    //this.checkNetwork(); 

    // Force page refreshes on network changes
      provider.on("network", (newNetwork, oldNetwork) => {
          // When a Provider makes its initial connection, it emits a "network"
          // event with a null oldNetwork along with the newNetwork. So, if the
          // oldNetwork exists, it represents a changing network
          if (oldNetwork) {
              window.location.reload();
          }
      });
    },

    watch: {
    'init.swap': function(newValue, oldValue) {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()

      if (newValue != oldValue) {
        if(newValue) {

          this.init.contract = new ethers.Contract(
            process.env.VUE_APP_DESTINATION_TOKEN_ADDRESS,
            DestinationToken.abi,
            signer
          );

          this.checkNetwork(); 

        } else {
          this.init.contract = new ethers.Contract(
            process.env.VUE_APP_ORIGIN_TOKEN_ADDRESS,
            HomsToken.abi,
            signer
          );

          this.checkNetwork(); 
        }
      }
    },
    }
  }
</script>

<style scoped lang="scss">
.loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
  #content { 
    color:white;
    background-color:black; 
    padding:40px;
    min-height:100vh;

    .search {
      width:400px;

      .search-input {
        background-image: linear-gradient(to bottom, #231f20, #070607);
        color:white;
        border:none;

        &::placeholder {
        color:#444444;
        font-family:OpenSans;
        }
      }

      .input-group-append {
        background-image: linear-gradient(to bottom, #231f20, #070607);
        
        .btn-search {
        color:#444444;
        }
      }
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        color: #333;
    }
    .hash {
        white-space: nowrap !important;
        width: 20em;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }

    td, th {
    
        text-align: left;
        padding: 1em;
        font-size: 0.75em;
    }

    td {  
        background-image: linear-gradient(to bottom, #231f20, #070607);
        color: #FFF;
        border-bottom: 1px solid #FFF;
        padding: 1.5em;
    
    }

    th {
        
        background-image: linear-gradient(to bottom, #231f20, #070607);
        color: #c08000;
    }

    th:first-child { 
        border-top-left-radius: 6px;
    }
    th:last-child { 
        border-top-right-radius: 6px;
    }

    .history-content {
        border-radius: 6px;
        overflow-x:auto;
        margin-top:40px;
    }
  }
</style> 